@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_tabbar {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  filter: drop-shadow(0px -1px 10px rgba(0, 0, 0, 0.05));
  background-color: @background_primary_color;
  display: flex;
  flex-direction: row;

  &_hidden {
    visibility: hidden;
  }
  &_item {
    flex: 1;
    #flex > .column();
    #flex > .center();

    &_icon {
      width: 24px;
      height: 24px;
      margin-top: 1px;
    }
    &_name {
      font-size: 10px;
      margin-top: 3px;
      color: @content_secondary_color;

      &_primary {
        color: @primary_color;
      }
    }
  }
}