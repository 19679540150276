@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_team {
  width: 100%;
  // padding: 0 12px;
  padding-bottom: 12px;
  // margin-top: -12px;
  box-sizing: border-box;
  #flex > .row();
}
.m_team_item {
  flex: 1;
  gap: 16px;
  border-radius: 12px;
  // padding: 12px;
  box-sizing: border-box;
  #flex > .column();

  &_head {
    #flex > .row(center);

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
    p {
      font-size: 14px;
      color: @primary_color;
      font-weight: @font_weight_strong;
    }
  }
  &_line {
    width: 100%;
    height: 1px;
    background-color: @border_color;
  }
  &_referral {
    width: 100%;
    gap: 8px;
    #flex > .column();

    .referral_label {
      font-size: 12px;
      color: @content_secondary_color;
    }
    .referral_container {
      width: 100%;
      padding: 12px;
      box-sizing: border-box;
      background-color: @background_secondary_color;
      border-radius: 8px;
      #flex > .row();
      #flex > .between();

      p {
        font-size: 14px;
        color: @primary_color;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
// .m_team_item_small {
//   background-color: #F4F8FE;
// }
.m_team_item_data {
  #flex > .column();

  &_key {
    #flex > .row(center);

    img {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
    p {
      font-size: 12px;
      color: @content_secondary_color;
    }
  }
  &_value {
    margin-top: 6px;
    gap: 2px;
    #flex > .row(center);

    p {
      font-size: 14px;
      color: @content_primary_color;
      font-weight: @font_weight_strong;
    }
    img {
      width: 12px;
      height: 12px;
      margin-top: -2px;
    }
  }
}