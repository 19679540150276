@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_binary {
  width: 100%;
  #flex > .column(center);

  &_image {
    width: 169px;
    height: 16px;
    margin: 8px 0;
  }
  &_node {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    gap: 12px;
    #flex > .row();
  }
  &_container {
    width: 100%;
    gap: 12px;
    padding: 0 24px;
    box-sizing: border-box;
    #flex > .row();
  }
  &_second {
    flex: 1;
    #flex > .column(center);

    &_image {
      width: 84px;
      height: 8px;
      margin: 8px 0;
    }
    &_node {
      width: 100%;
      gap: 12px;
      #flex > .row();
    }
  }
}
.m_binary_item_container {
  flex: 1;

  .m_binary_item {
    flex: 1;
    background-color: @background_primary_color;
    border-radius: 16px;
    padding: 24px 0;
    position: relative;
    #flex > .column(center);
  
    &_avatar {
      width: 36px;
      height: 36px;
      border-radius: 36px;
      object-fit: cover;
    }
    &_name {
      font-size: 12px;
      color: @content_primary_color;
      font-weight: @font_weight_strong;
      margin-top: 6px;
    }
    &_empty {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: @border_color;
      z-index: 2;
      display: flex;
      border-radius: 16px;
      gap: 24px;
      #flex > .column();
      #flex > .center();
  
      img {
        height: 50%;
      }
    }
  }
  .m_binary_item_second {
    width: 100%;
    #flex > .column(center);

    &_image {
      width: 43px;
      height: 8px;
      margin: 8px 0;
    }
    &_node {
      width: 100%;
      #flex > .row();
      #flex > .between();

      img {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        object-fit: cover;
      }
    }
  }
}