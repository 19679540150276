@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_summary {
  width: 100%;
  gap: 16px;
  #flex > .column();
}
.m_summary_item {
  width: 100%;
  #flex > .row(center);

  &_key {
    font-size: 14px;
    color: @content_inverse_primary_color;
    opacity: 0.7;
  }
  &_value {
    flex: 1;
    font-size: 14px;
    color: @content_inverse_primary_color;
    font-weight: @font_weight_strong;
    margin: 0 8px;
    text-align: right;
  }
  &_arrow {
    width: 16px;
    height: 16px;
    opacity: 0.7;
  }
}