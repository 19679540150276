@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_bot {
  width: 100%;
  #flex > .column(center);

  &_tabs {
    width: calc(100% - 48px);
    padding: 24px 0;
    box-sizing: border-box;
    border-bottom: 1px solid @border_color;
    #flex > .row();

    .tab_item {
      flex: 1;
      gap: 4px;
      #flex > .row();
      #flex > .center();

      p {
        font-size: 12px;
        color: @content_secondary_color;
      }
      img {
        width: 12px;
        height: 12px;
      }
      &:first-child {
        justify-content: flex-start;
      }
      &:last-child {
        justify-content: flex-end;
      }
    }
  }
  &_list {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    gap: 24px;
    #flex > .column();

    .item_container {
      width: 100%;
      #flex > .row();

      .content {
        flex: 1;
        gap: 6px;
        #flex > .row();
        #flex > .center();

        p {
          font-size: 14px;
          color: @content_primary_color;
        }
        img {
          width: 16px;
          height: 16px;
        }
        &:first-child {
          justify-content: flex-start;
        }
        &:last-child {
          justify-content: flex-end;

          p {
            font-weight: @font_weight_strong;
          }
        }
      }
    }
  }
}