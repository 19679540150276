@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_network {
  width: 100%;
  margin: 16px 0;
  background-color: @background_primary_color;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  #flex > .column();

  &_title {
    font-size: 16px;
    color: @content_primary_color;
    font-weight: @font_weight_strong;
  }
  &_tab {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid @border_color;
    gap: 10px;
    #flex > .row(center);

    &_item {
      flex: 1;
      gap: 4px;
      #flex > .row();
      #flex > .center();

      p {
        font-size: 12px;
        color: @content_secondary_color;
      }
      img {
        width: 12px;
        height: 12px;
      }
      &:first-child {
        justify-content: flex-start;
      }
      &:last-child {
        justify-content: flex-end;
      }
    }
  }
  &_list {
    width: 100%;
    margin-top: 16px;
    gap: 16px;
    #flex > .column();

    .network_item {
      width: 100%;
      gap: 10px;
      #flex > .row(center);
    }

    p {
      flex: 1;
      gap: 4px;
      color: @content_primary_color;
      #flex > .row();
      #flex > .center();

      span {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-right: 6px;
        background-color: @content_negative_color;
      }
      .active {
        background-color: @content_positive_color;
      }
      &:first-child {
        font-weight: @font_weight_strong;
        justify-content: flex-start;
      }
      &:last-child {
        justify-content: flex-end;
      }
    }
  }
  &_more {
    width: 100%;
    height: 36px;
    font-size: 14px;
    border-radius: 8px;
    margin-top: 16px;
    #button > .normal();

    & > span {
      gap: 6px;
      #flex > .row();
    }
    img {
      width: 16px;
      height: 16px;
    }
  }
}