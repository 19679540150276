@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_bandwidth {
  width: 100%;
  margin-top: 16px;
  background-color: @background_primary_color;
  border-radius: 16px;
  padding: 0 16px;
  padding-bottom: 16px;
  box-sizing: border-box;

  &_head {
    width: 100%;
    padding: 16px 0;
    #flex > .row(center);

    .name {
      font-size: 16px;
      font-weight: @font_weight_strong;
      color: @content_primary_color;
      flex: 1;
    }
    img {
      width: 16px;
      height: 16px;
      margin: 0 4px;
    }
    .amount {
      font-size: 16px;
      font-weight: @font_weight_strong;
      color: @primary_color;

      span {
        color: @primary_fourtiary_color;
      }
    }
  }
  &_process_bar {
    width: 100%;
    --track-width: 8px;
    --track-color: @background_secondary_color;
    --fill-color: @primary_color;
  }
  &_more {
    width: 100%;
    margin-top: 16px;
    height: 36px;
    font-size: 14px;
    border-radius: 8px;
    #button > .primary();

    & > span {
      gap: 6px;
      #flex > .row();
    }
    img {
      width: 16px;
      height: 16px;
    }
  }
}