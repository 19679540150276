@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_nodata {
  flex: 1;
  width: 100%;
  background-color: @background_primary_color;
  padding: 48px;
  box-sizing: border-box;
  #flex > .column(center);

  &_icon {
    height: 88px;
    object-fit: cover;
  }
  &_title {
    font-size: 16px;
    color: @content_primary_color;
    font-weight: @font_weight_strong;
    margin-top: 24px;
  }
  &_content {
    width: 100%;
    margin-top: 8px;
    color: @content_secondary_color;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
  }
  &_button {
    padding: 0 16px;
    height: 36px;
    font-size: 14px;
    border-radius: 8px;
    margin-top: 24px;
    #button > .primary();
  }
}