@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_lifetime {
  width: 100%;
  margin-top: 16px;
  background-color: @background_primary_color;
  border-radius: 16px;
  padding: 0 16px;
  padding-bottom: 16px;
  box-sizing: border-box;

  &_head {
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid @border_color;
    #flex > .row(center);

    .name {
      font-size: 16px;
      font-weight: @font_weight_strong;
      color: @content_primary_color;
      flex: 1;
    }
    img {
      width: 16px;
      height: 16px;
      margin: 0 4px;
    }
    .amount {
      font-size: 16px;
      font-weight: @font_weight_strong;
      color: @primary_color;
    }
  }
  &_content {
    width: 100%;
    padding: 16px 0;

    .content_keys { 
      width: 100%;
      gap: 12px;
      #flex > .row();

      p {
        flex: 1;
        font-size: 12px;
        color: @content_secondary_color;
        white-space: pre-wrap;
      }
    }
    .content_values {
      width: 100%;
      gap: 12px;
      margin-top: 6px;
      #flex > .row();

      p {
        flex: 1;
        font-size: 16px;
        color: @content_primary_color;
        font-weight: @font_weight_strong;
      }
    }
  }
  &_process_bar {
    width: 100%;
    --track-width: 8px;
    --track-color: @background_secondary_color;
    --fill-color: @primary_color;
  }
}
.m_lifetime_rewards {
  width: 100%;
  margin-top: 28px;
  #flex > .row();
  #flex > .between();

  .lifetime_content {
    gap: 8px;
    #flex > .column();

    .lifetime_name {
      font-size: 16px;
      color: @content_primary_color;
      font-weight: @font_weight_strong;
    }
    .lifetime_desc {
      gap: 6px;
      #flex > .row(center);

      img {
        width: 32px;
        height: 32px;
      }
      p {
        font-size: 32px;
        color: @content_primary_color;
        font-weight: @font_weight_strong;
      }
    }
  }
  .lifetime_process_circle {
    --size: 68px;
    --track-width: 8px;
    --track-color: @background_secondary_color;
    --fill-color: @primary_color;

    &_key {
      font-size: 12px;
      color: @content_secondary_color;
    }
    &_value {
      font-size: 16px;
      color: @primary_color;
      font-weight: @font_weight_strong;
    }
  }
}
.m_network_rewards {
  width: 100%;
  background-color: @primary_color;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;
  position: relative;
  gap: 8px;
  #flex > .column();

  &_name {
    font-size: 14px;
    color: @content_inverse_primary_color;
  }
  &_content {
    width: 100%;
    #flex > .row(center);

    .today_amount {
      flex: 1;
      font-size: 24px;
      color: @content_inverse_primary_color;
      font-weight: @font_weight_strong;
    }
    .total_amount {
      font-size: 14px;
      color: @content_inverse_primary_color;
      font-weight: @font_weight_strong;

      span {
        opacity: 0.7;
        font-weight: 400;
      }
    }
    .arrow {
      width: 16px;
      height: 16px;
      margin-left: 8px;
    }
  }
  &_bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    overflow: hidden;
    #flex > .row();
    justify-content: space-between;

    .bg1 {
      width: calc(100% - 80px);
    }
    .bg2 {
      width: 80px;
    }
  }
}