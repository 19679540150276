@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_card {
  width: 100%;
  border-radius: 16px;
  margin-top: 16px;
  background-color: @content_primary_color;
  position: relative;
  #flex > .column();

  &_container {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 16px;
    #flex > .column();

    .logo_title {
      #flex > .row(center);

      img {
        width: 24px;
        height: 24px;
        border-radius: 24px;
      }
      p {
        margin-left: 8px;
        font-size: 16px;
        font-weight: @font_weight_strong;
        color: @content_inverse_primary_color;
      }
    }
    .amount_extra {
      width: 100%;
      margin-top: 9px;
      #flex > .row();
      #flex > .between();

      .amount {
        font-size: 24px;
        font-weight: @font_weight_strong;
        color: @content_inverse_primary_color;
      }
    }
  }
  &_reserve_container {
    width: 100%;
    padding: 16px 24px;
    box-sizing: border-box;
    #flex > .row();
    #flex > .between();

    .key {
      font-size: 14px;
      color: @content_inverse_primary_color;
      opacity: 0.7;
    }
    .value {
      font-size: 14px;
      font-weight: @font_weight_strong;
      color: @content_inverse_primary_color;
    }
  }
  &_bg {
    position: absolute;
    width: calc(100% - 93px);
  }
}