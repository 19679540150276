@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_error {
  width: 100vw;
  height: 100vh;
  background-color: @background_primary_color;
  padding: 0 24px;
  gap: 24px;
  box-sizing: border-box;
  #flex > .column();
  #flex > .center();

  &_content {
    font-size: 14px;
    color: @content_primary_color;
    text-align: center;
  }
  &_button {
    padding: 0 20px;
    height: 36px;
    font-size: 14px;
    border-radius: 8px;
    #button > .primary();
  }
}