@font-face{
  font-family: "-text-sora";
  src: url("~@/assets/font/Sora.ttf");
}

@font_family: -text-sora;
@font_weight_strong: 600;
@primary_color: #FF7700;
@primary_secondary_color: #967ADC;
@primary_tertiary_color: #CBBCED;
@primary_fourtiary_color: #90BDF5;
@primary_fiftiary_color: #EAE4F8;
@content_primary_color: #263240;
@content_inverse_primary_color: #FFFFFF;
@content_secondary_color: #888D8F;
@content_tertiary_color: #B6BDBF;
@content_negative_color: #ED5565;
@content_positive_color: #37BC9B;
@border_color: #E8E9EB;
@background_primary_color: #FFFFFF;
@background_secondary_color: #E3F0FF;
@background_tertiary_color: #F3F4F5;
@background_fourtiary_color: #F5F6F7;
@background_fiftiary_color: rgba(255, 255, 255, 0.20);

:root {
  --primary_color: @primary_color;
  --primary_secondary_color: @primary_secondary_color;
  --content_positive_color: @content_positive_color;
  --content_secondary_color: @content_secondary_color;
  --border_color: @border_color;
  --background_fourtiary_color: @background_fourtiary_color;
  --background_primary_color: @background_primary_color;
}