@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_navigation {
  width: 100%;
  background-color: @background_primary_color;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;
  #flex > .row();
  #flex > .between();

  p {
    font-size: 20px;
    color: @content_primary_color;
    font-weight: @font_weight_strong;
  }
  img {
    width: 16px;
    height: 16px;
  }
}