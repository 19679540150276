@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_form {
  width: 100%;
  #flex > .column();

  &_button {
    width: 100%;
    margin-top: 40px;
    height: 56px;
    font-size: 16px;
    border-radius: 16px;
    #button > .primary();
  }
}