@import "~@/assets/styles/variables.less";

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td, button {
  margin: 0;
  padding: 0;
  font-family: @font_family, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  line-height: 1.25;
}
body {
  background-color: @background_primary_color;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset, img {
  border: 0;
  user-select: none;
}
ol, ul, li {
  list-style: none;
}
q:before, q:after {
  content: "";
}
abbr, acronym { 
  border: 0; 
}
a:-webkit-any-link {
  text-decoration: none;
}
.adm-pull-to-refresh {
  width: 100%;
}