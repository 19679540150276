@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_activity {
  width: 100%;
  #flex > .column();
  
  &_title {
    font-size: 20px;
    font-weight: @font_weight_strong;
    color: @content_primary_color;
    margin-top: 20px;
    padding: 12px 0;
    background-color: @background_primary_color;
    width: 100%;
    position: sticky;
    top: -1px;
    left: 0;
  }
  &_list {
    width: 100%;
    margin-bottom: 12px;
    #flex > .column();

    .item {
      width: 100%;
      margin: 12px 0;
      #flex > .row(center);

      .icon {
        width: 36px;
        height: 36px;
        border-radius: 36px;
        margin-right: 12px;
      }
      .content {
        display: flex;
        flex: 1;
        gap: 3px;
        #flex > .column();

        &_row {
          width: 100%;
          #flex > .row();
          #flex > .between();

          &_type, &_time {
            font-size: 12px;
            color: @content_secondary_color;
          }
          &_desc {
            font-size: 14px;
            color: @content_primary_color;
            font-weight: @font_weight_strong;
          }
          &_status {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}