@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_item {
  width: 100%;
  padding: 14px 0;
  #flex > .row(center);
  border-bottom: 1px solid @border_color;

  &:last-child {
    border-bottom-style: none;
  }
  &_icon {
    width: 36px;
    height: 36px;
    margin-right: 16px;
  }
  &_label {
    flex: 1;
    font-size: 16px;
    line-height: 1.5;
    color: @content_primary_color;
    font-weight: @font_weight_strong;
  }
  &_content {
    flex: 1;
    font-size: 16px;
    color: @content_secondary_color;
    margin-top: 6px;
  }
  &_arrow {
    width: 16px;
    height: 16px;
  }
  &_info {
    flex: 1;
    #flex > .column();
  }
}