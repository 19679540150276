@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_kline {
  width: 100%;
  background-color: @background_primary_color;
  border-radius: 16px;
  margin-top: 16px;
  #flex > .column();

  &_head {
    width: 100%;
    padding: 16px;
    padding-bottom: 0;
    box-sizing: border-box;
    #flex > .row();
    #flex > .between();

    .title {
      font-size: 16px;
      color: @content_primary_color;
      font-weight: @font_weight_strong;
    }
    .extra {
      gap: 2px;
      #flex > .row(center);

      p {
        font-size: 14px;
        color: @content_positive_color;
        font-weight: @font_weight_strong;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  &_echart_container {
    width: 100%;
    height: 120px;
    margin: 16px 0;
    box-sizing: border-box;
  }
  &_tabs {
    width: 100%;
    gap: 40px;
    #flex > .row();
    #flex > .center();

    .tab_item {
      color: @content_secondary_color;
      font-size: 12px;
      padding: 5px 8px;
      border-radius: 8px;
    }
    .tab_item_sel {
      color: @primary_color;
      font-weight: @font_weight_strong;
      background-color: @background_secondary_color;
    }
  }
}