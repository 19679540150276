@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_navbar {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  z-index: 999;
  #flex > .row(center);

  &_back {
    width: 16px;
    height: 16px;
    padding: 4px;
    margin-left: 20px;
  }
  &_title {
    font-size: 16px;
    font-weight: @font_weight_strong;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &_title_light {
    color: @content_inverse_primary_color;
  }
  &_title_dark {
    color: @content_primary_color;
  }
}
.m_navbar_light {
  background-color: @background_primary_color;
}
.m_navbar_dark {
  background-color: @primary_color;
}

.m_home_navbar {
  width: 100vw;
  height: 64px;
  background-color: @background_fourtiary_color;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 0 24px;
  box-sizing: border-box;
  #flex > .row(center);

  &_logo {
    width: 114px;
    height: 32px;
  }
  &_message {
    width: 24px;
    height: 24px;
    margin-left: 16px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  &_popup {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    #flex > .column();

    img {
      width: 16px;
      height: 16px;
      padding: 20px 24px;
    }
    .title {
      font-size: 24px;
      color: @content_primary_color;
      font-weight: @font_weight_strong;
      line-height: 1.5;
      padding: 0 24px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 12px;
    }
    .content {
      font-size: 16px;
      color: @content_primary_color;
      line-height: 2;
      padding: 0 24px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 16px;
      margin-bottom: 40px;
      white-space: pre-wrap;
    }
  }
}