@import "~@/assets/styles/variables.less";
@import "~@/assets/styles/mixins.less";

.m_co {
  width: 100%;
  background-color: @background_primary_color;
  border-radius: 16px;
}
.m_connect {
  width: 100%;
  background-color: @primary_secondary_color;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  margin-top: 8px;
  #flex > .row(center);

  &_icon {
    width: 24px;
    height: 24px;
  }
  &_ip_info {
    flex: 1;
    gap: 4px;
    margin: 0 8px;
    #flex > .column();

    p {
      color: @content_inverse_primary_color;
      font-size: 11px;
    }
  }
  &_connect {
    padding: 0 12px;
    height: 36px;
    border-radius: 8px;
    background-color: @background_primary_color;
    border-style: none;

    &:active::before {
      border-radius: 8px;
      border-style: none;
    }
    & > span {
      gap: 4px;
      #flex > .row(center);
    }

    .connect_icon {
      width: 16px;
      height: 16px;
    }
    .connect_ed {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: @background_primary_color;
    }
    p {
      font-size: 14px;
      color: @primary_secondary_color;
      font-weight: @font_weight_strong;
    }
  }
  &_connected {
    background-color: @background_fiftiary_color;

    p {
      color: @content_inverse_primary_color;
    }
  }
}
.m_coupon {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;

  &_head {
    width: 100%;
    #flex > .row(center);

    .head_name {
      font-size: 16px;
      color: @content_primary_color;
      font-weight: @font_weight_strong;
      flex: 1;
    }
    .head_icon {
      width: 16px;
      height: 16px;
      margin: 0 4px;
    }
    .head_amount {
      font-size: 16px;
      color: @primary_secondary_color;
      font-weight: @font_weight_strong;

      span {
        color: @primary_tertiary_color;
      }
    }
  }
  &_process_bar {
    width: 100%;
    margin-top: 16px;
    --track-width: 8px;
    --track-color: @primary_fiftiary_color;
    --fill-color: @primary_secondary_color;
  }
}